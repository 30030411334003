.section {
  position: relative;
  width: 100%;
  left: 0;
  margin-left: 0;
  margin-right: 0;
  font-size: 18px;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #272E65;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 40px 0;
}

.content {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.title {
  font-weight: bold;
  font-size: 40px;
  white-space: nowrap;
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 16px 0;
}

.text {
  margin: 0 0 16px 0;
  font-size: 21px;
  font-weight: 400;
}

.ticketList {
  line-height: 1.15;
}

.ticketName {
  display: inline-block;
  width: 140px;
}

.ticketText {
  font-size: 18px;
  vertical-align: middle;
  display: inline-block;
  height: calc(1.15 * 21px);
}

.link {
  text-align: center;
  margin: 0 0 16px 0;
}

@media (min-width: 576px) {
  .container {
    max-width: 500px;
  }

  .content {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 568px;
  }

  .content {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 792px;
  }

  .content {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1000px;
  }

  .content {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.button {
  background-color: #272E65;
  padding: 4px 8px;
  text-align: center;
  vertical-align: middle;
  font-size: 24px;
  transition: color 0.4s ease 0s, background-color 0.4s ease 0s;
  -moz-transition: color 0.4s ease 0s, background-color 0.4s ease 0s;
  -webkit-transition: color 0.4s ease 0s, background-color 0.4s ease 0s;
  -o-transition: color 0.4s ease 0s, background-color 0.4s ease 0s;
  -ms-transition: color 0.4s ease 0s, background-color 0.4s ease 0s;
  color: #fff !important;
  text-decoration: none;
  justify-content: center;
}

.button:hover {
  color: #605e5e !important;
  background-color: rgba(108, 140, 199, 1);
  cursor: pointer;
}
