.background {
  top: 0px;
  background-color: rgb(255, 255, 255);
  position: fixed;
  width: 100%;
  height: 100vh;
}

.background > .img {
  position: absolute;
  top: 0px;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  background-image: url('../../assets/images/background-xsmall.png?v=1');
}

@media (min-width: 760px) {
  .background > .img {
    background-image: url('../../assets/images/background-small.png?v=1');
    width: 101%;
  }
}

@media (min-width: 992px) {
  .background > .img {
    background-image: url('../../assets/images/background-big.png?v=1');
    width: 101%;
  }
}
