.section {
  position: relative;
  width: 100%;
  left: 0;
  margin-left: 0;
  margin-right: 0;
  font-size: 18px;
  color: #272E65;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 50px 0;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 100%;
}

.title {
  font-weight: bold;
  font-size: 30px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 0;
}

@media (min-width: 576px) {
  .container {
    max-width: 972px;
  }

  .content {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.text {
  margin: 0 0 16px 0;
  font-size: 18px;
  font-weight: 400;
}

.img {
  max-width: 500px;
  max-height: 500px;
  width: 80vw;
  height: 80vw;
  object-fit: cover;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.links {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.link {
  color: #b81c55;
  text-decoration: none;
  padding: 10px;
  transition: color 0.3s ease;
}

.link:hover {
  color: #485fa3;
  cursor: pointer;
}