.section {
  position: relative;
  width: 100%;
  left: 0;
  margin-left: 0;
  margin-right: 0;
  font-size: 18px;
  color: #fff;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(108, 140, 199, 0.51);
  padding: 30px 0;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.title {
  font-weight: bold;
  font-size: 48px;
  white-space: nowrap;
  text-transform: uppercase;
  text-align: center;
}

.text {
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }

  .content {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }

  .content {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }

  .content {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }

  .content {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
}
