.component {
  background-color: #161D35;
  height: 100%;
}

.background {
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0.5;
}

.item .header {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  bottom: 50%;
  height: 110px;
  margin: auto -50%;
  text-align: center;
  transition: all 0.5s ease-out;
}

.header {
  position: absolute;
  color: white;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 0;
  flex-direction: column;
  text-align: center;
}

.title {
  font-size: 32px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 8px;
}

@media (min-width: 380px) {
  .title {
    font-size: 46px;
  }
}

@media (min-width: 350px) {
  .title {
    font-size: 40px;
  }
}

@media (min-width: 501px) {
  .title {
    font-size: 32px;
  }
}

.button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
}

.button:hover {
  color: #605e5e !important;
  background-color: rgb(155, 153, 193);
  cursor: pointer;
}

.button {
  text-align: center;
  vertical-align: middle;
  font-size: 24px;
  transition: color 0.4s ease 0s, background-color 0.4s ease 0s;
  -moz-transition: color 0.4s ease 0s, background-color 0.4s ease 0s;
  -webkit-transition: color 0.4s ease 0s, background-color 0.4s ease 0s;
  -o-transition: color 0.4s ease 0s, background-color 0.4s ease 0s;
  -ms-transition: color 0.4s ease 0s, background-color 0.4s ease 0s;
  color: #fff !important;
  background-color: rgba(255, 255, 255, 0.39);
  text-decoration: none;
  justify-content: center;
}
