.section {
  position: relative;
  width: 100%;
  left: 0;
  margin-left: 0;
  margin-right: 0;
  font-size: 18px;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #F4BD1F;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}

.content {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.title {
  font-weight: bold;
  font-size: 38px;
  white-space: nowrap;
  text-transform: uppercase;
  text-align: left;
  margin: 0 0 16px 0;
}

.subtitle {
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  color: #272E65;
}

.text {
  margin: 0 0 16px 0;
  font-size: 16px;
  font-style: italic;
}

.link {
  font-weight: bold;
  text-decoration: none;
  color: inherit;
  font-family: inherit;
}

@media (min-width: 576px) {
  .container {
    max-width: 500px;
  }

  .content {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 568px;
  }

  .content {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 792px;
  }

  .content {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1000px;
  }

  .content {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
