.section {
  position: relative;
  width: 100%;
  left: 0;
  margin-left: 0;
  margin-right: 0;
  font-size: 18px;
  color: #272E65;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 50px 0;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 100%;
}

.title {
  font-weight: bold;
  font-size: 60px;
  letter-spacing: 0.1em;
  white-space: nowrap;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 0;
}

@media (min-width: 576px) {
  .container {
    max-width: 972px;
  }

  .content {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}