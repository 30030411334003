.section {
  position: relative;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 0;
}

.title {
  text-transform: lowercase;
  font-size: 60px;
  font-weight: bold;
  line-height: 1.5;
}

.content {
}

.content img {
  height: 60px;
}
