/* Maintenance.module.css */

.maintenanceContainer {
  width: 100vw;
  height: 100vh;
  background-color: #fff;

  /* Flexbox-centrering */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* Eventueel: font, kleuren, marges, etc. */
  font-family: Arial, sans-serif;
  text-align: center;
}

.maintenanceLogo {
  width: 600px;
  max-width: 80VW;
  margin-bottom: 20px;
}

.maintenanceDate {
  font-size: 2rem;
  color: #333;
}

.ticketLink {
  display: inline-block;
  background-color: #485fa3;
  color: #ffffff;
  padding: 15px 30px;
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.ticketLink:hover {
  background-color: #3a4a89;
}
