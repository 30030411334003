.component {
  background-color: #161D35;
  height: 100%
}

.background {
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0.5;
}

.item .header {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  bottom: 50%;
  height: 110px;
  margin: auto -50%;
  text-align: center;
  transition: all 0.5s ease-out;
}

.header {
  position: relative;
  color: white;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  text-align: center;
}

@media (min-width: 350px) {
  .header {
    padding-top: 30px;
  }
}

@media (min-width: 501px) {
  .header {
    padding-top: 40px;
  }
}

.headerDay {
  font-size: 21px;
}

.headerTitle {
  font-size: 23px;
}

.headerHour {
  font-size: 20px;
}

.text {
  position: absolute;
  color: white;
  left: 10px;
  right: 10px;
  font-size: 15px;
  top: 50%;
  padding-top: 40px;
  transform: translateY(-50%);
}

@media (min-width: 501px) {
  .headerDay {
    font-size: 29px;
  }

  .headerTitle {
    font-size: 32px;
  }

  .headerHour {
    font-size: 27px;
  }
}

@media (min-width: 350px) {
  .text {
    left: 15px;
    right: 15px;
    font-size: 16px;
    padding-top: 70px;
  }
}

@media (min-width: 380px) {
  .text {
    left: 30px;
    right: 30px;
    font-size: 18px;
    padding-top: 50px; 
  }
}

@media (min-width: 501px) {
  .text {
    left: 40px;
    right: 40px;
    font-size: 20px;
    padding-top: 60px;
  }
}

@media (min-width: 992px) {
  .text {
    left: 200px;
    right: 200px;
  }
}

.button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
}

.button:hover {
  color: #605e5e !important;
  background-color: rgb(108, 140, 199);
  cursor: pointer;
}

.button {
  text-align: center;
  vertical-align: middle;
  font-size: 24px;
  transition: color 0.4s ease 0s, background-color 0.4s ease 0s;
  -moz-transition: color 0.4s ease 0s, background-color 0.4s ease 0s;
  -webkit-transition: color 0.4s ease 0s, background-color 0.4s ease 0s;
  -o-transition: color 0.4s ease 0s, background-color 0.4s ease 0s;
  -ms-transition: color 0.4s ease 0s, background-color 0.4s ease 0s;
  color: #fff !important;
  background-color: rgba(255, 255, 255, 0.39);
  text-decoration: none;
  justify-content: center;
}
