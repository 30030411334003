.burger-button {
  width: 40px;
  height: 30px;
  margin: 6px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 1000;
}

.burger-button span {
  display: block;
  position: absolute;
  height: 6px;
  width: 100%;
  background: #272E65;
  border-radius: 6px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.burger-button span:nth-child(1) {
  top: 0px;
}

.burger-button span:nth-child(2),
.burger-button span:nth-child(3) {
  top: 12px;
}

.burger-button span:nth-child(4) {
  top: 24px;
}

.burger-button.open span:nth-child(1) {
  top: 12px;
  width: 0%;
  left: 50%;
}

.burger-button.open span:nth-child(2) {
  transform: rotate(45deg);
}

.burger-button.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.burger-button.open span:nth-child(4) {
  top: 12px;
  width: 0%;
  left: 50%;
}
