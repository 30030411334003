.header {
  position: relative;
}

.headerBar {
  position: absolute;
  top: 0;
  height: 40px;
  width: 100%;
  background-color: rgba(237, 237, 228, 0.8);
}

.mobileMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(237, 237, 228);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1;
  padding-top: 42px;
}

.mobileMenu.open {
  transform: translateX(0);
}

.navigationMenu {
  display: flex;
  justify-content: flex-start;
  gap: 24px;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 40px;
}

.navigationMenu li {
  line-height: 40px;
}

.navigationMenu a {
  color: #272E65;
  text-decoration: none;
}

.navigationMenu a:hover {
  color: #0056b3;
  text-decoration: underline;
}

@media (max-width: 767px) {
  .headerBar {
    position: fixed;
    z-index: 100;
    padding-top: 0; /* Fallback padding */
    padding-top: env(safe-area-inset-top, 0);
  }

  .navigationMenu {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 20px;
    gap: 14px;
  }
}

.mobileOnly {
  position: fixed;
  z-index: 100;
}

.desktopOnly {
  position: relative;
}

/* Mobile-only styles */
@media (min-width: 0px) and (max-width: 767px) {
  .mobileOnly {
    display: block;
  }

  .desktopOnly {
    display: none;
  }
}

/* Desktop-only styles */
@media (min-width: 768px) {
  .mobileOnly {
    display: none;
  }

  .desktopOnly {
    display: block;
  }
}

.logo {
  position: relative;
  height: 280px;
  padding-top: 40px;
}

.logo img {
  position: absolute;
  top: 50px;
  max-width: 80%;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.logo .caption {
  position: absolute;
  top: 250px;
  max-width: 100%;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  color: white;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

@media (min-width: 576px) {
  .logo {
    height: 441px;
  }

  .logo img {
    top: 62px;
    width: 500px;
    max-width: 100%;
  }

  .logo .caption {
    top: 372px;
    width: 500px;
    font-size: 23px;
  }
}

@media (min-width: 768px) {
  .logo {
    height: 500px;
  }

  .logo img {
    top: 62px;
    width: 600px;
  }

  .logo .caption {
    top: 430px;
    width: 600px;
    font-size: 28px;
  }
}

@media (min-width: 992px) {
  .logo {
    height: 600px;
  }

  .logo img {
    top: 62px;
    width: 800px;
  }

  .logo .caption {
    top: 530px;
    width: 800px;
    font-size: 35px;
  }
}
