.grid {
  display: grid;
  gap: 10px;
}

/* Default layout: 1 column, 2:1 aspect ratio on desktop, 1:1 on mobile */
.grid.default {
  grid-template-columns: 1fr;
}

.grid.default .gridItem {
  width: 100%;
  height: auto;
  position: relative;
}

.grid.default .gridItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .grid.default .gridItem {
    aspect-ratio: 2 / 1; /* Aspect ratio 2:1 */
  }
}

@media (max-width: 767px) {
  .grid.default .gridItem {
    aspect-ratio: 1 / 1; /* Aspect ratio 1:1 */
  }
}

/* New layout: 2 columns of squares on desktop, 1 column on mobile */
.grid.twoColumn {
    grid-template-columns: 1fr;
  }
  
  .grid.twoColumn .gridItem {
    width: 100%;
    aspect-ratio: 1 / 1; /* Aspect ratio 1:1 */
    position: relative;
  }
  
  .grid.twoColumn .gridItem img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @media (min-width: 768px) {
    .grid.twoColumn {
      grid-template-columns: repeat(2, 1fr);
    }
  }
